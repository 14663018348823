.app-container {
  display: flex;
  height: 100vh;
}

.sidebar {
  background-color: #333;
  color: #fff;
  overflow: hidden;
  transition: flex 0.3s ease; 
}

.content-container {
  flex: 1; 
  padding: 20px;
  overflow: auto; 
}

@media (max-width: 768px) {
  .app-container {
    flex-direction: row; 
  }

  .content-container {
    flex: 1; 
  }
}
