.summary-container {
  max-width: 100%;
  margin: auto;
  padding: 20px;
  background-color: #f7f7f7;
  color: #e55855;
  background-color: black;
}

.head {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
}

.head2 {
  text-align: center;
  font-size: 25px;
}

.summary-details {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.summary-field {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 30px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  

}

.label {
  font-weight: bold;
  margin-bottom: 5px;
}

.value {
  font-size: 18px;
}

.input {
  width: 100%;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 3px;
  font-size: 14px;
}

.loading {
  text-align: center;
  font-size: 20px;
  margin: 20px;
}

.info-icon {
  margin-left: 10px;
  cursor: pointer;
}

/* SummaryReport.css */

.summary-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.summary-table th,
.summary-table td {
  padding: 8px 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.summary-table th {
  background-color: black;
  color: #fff;
  font-weight: bold;
}


.right-button {
  float: right; 
  margin-right: 150px;
}

.paid {
  background-color: lightgreen;
}


