.image{
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 10px;
    
}

.main_container{
    margin: 30px 0px;
    padding: 10px;
    background-color: rgb(245, 240, 240);
    border-radius: 10px;
}
.text_body{
    margin: 5px 5px;
}

.text_body > h1{
    color: black;
    font-size: 30px;
}
.text_body > p{
    color: black;
    font-size: 20px;
}

.main_container > div > h4{
    color: black;
}
.main_container > div > h5{
    color: black;
}
