.tournament-card {
  color: white;
  display: flex;
  flex-wrap: wrap; /* Allow the content to wrap on smaller screens */
  background-color: #e55855;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  margin-bottom: 20px;
  font-family: Arial, sans-serif;
}

.circular-image {
  margin-top: 2%;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
  flex: 0 0 160px; /* Set a fixed width for the circular image */
}

.circular-image{
  margin-top: 3%;
  margin-left: 1%;
}

img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: black;
}

.tournament-details {
  flex: 1;
  padding: 20px;
}

.tournament-details h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  font-weight: bold;
}

.tournament-details p {
  margin: 5px 0;
  font-weight: bold;
}

.buttons-container {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap; /* Allow the buttons to wrap on smaller screens */
}

/* Adjust the buttons width and margin on smaller screens */
.categories-button,
.edit-button,
.delete-button,
.download-button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  color: #fff;
  margin: 5px;
}

.custom-file-upload {
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  color: #fff;
  margin: 5px;
  background-color: #3498db;
  transition: background-color 0.3s, border-color 0.3s;
}

.categories-button {
  background-color: #4caf50;
}

.edit-button {
  background-color: #f39c12;
}

.delete-button {
  background-color: #e74c3c;
}

.download-button {
  background-color: #272829;
}

.categories-button:hover,
.edit-button:hover,
.delete-button:hover {
  opacity: 0.8;
}

.card-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  color: #e55855;
}

.card-modal-content {
  padding: 20px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 20px rgba(0, 0, 0, 1);
  max-width: 600px; /* Set a maximum width for the modal content */
  margin: auto; /* Center the modal content horizontally */
  position: relative;
}

.card-close {
  position: absolute;
  top: 3px;
  right: 3px;
  font-size: 20px;
  color: #444;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.card-close:hover {
  background-color: #ff3838;
}

.card-modal-content input[type="text"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.scroll {
  display: flex;
  overflow-x: hidden;
  overflow-y: scroll;
}

.scroll::-webkit-scrollbar{
  display: none;
}

.card-modal-content button.btn {
  padding: 10px 20px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;
  margin-left: 40%;
}

.card-modal-content button.btn:hover {
  background-color: #45a049;
}

.custom-file-upload:hover {
  background-color: #2980b9;
  border-color: #2980b9;
}

@media screen and (max-width: 600px) {
  .circular-image {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .buttons-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .buttons-container button {
    margin: 5px;
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.tournament-card {
  animation: slideIn 0.5s ease;
}

@keyframes btnBounce {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}
