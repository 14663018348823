body {
  background-color: #f9f9f9;
}

table {
  width: 100%; /* Set the width to 100% to make the table responsive */
}

/* Add your other styles here */
.input-border-active {
  border-color: #ff0000; /* Change the color to your desired value */
}

th,
td {
  border-bottom: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

/* CSS */
.table-container {
  overflow-x: auto; /* To handle overflow when table is wider than the container */
}

.fixed-table {
  table-layout: fixed;
  width: 120%; /* Ensure the table takes the full width of the container */
}

.fixed-table th, .fixed-table td {
  padding: 8px; 
}

.fixed-table th:nth-child(1),
.fixed-table td:nth-child(1) {
  width: 50px;
}

.fixed-table th:nth-child(2),
.fixed-table td:nth-child(2) {
  width: 70px; 
}

.fixed-table th:nth-child(3),
.fixed-table td:nth-child(3) {
  width: 150px; 
}

.fixed-table th:nth-child(4),
.fixed-table td:nth-child(4) {
  width: 150px; 
}

.fixed-table th:nth-child(5),
.fixed-table td:nth-child(5) {
  width: 150px; 
}

.fixed-table th:nth-child(6),
.fixed-table td:nth-child(6) {
  width: 150px; 
}

.fixed-table th:nth-child(7),
.fixed-table td:nth-child(7) {
  width: 150px;
}

.fixed-table th:nth-child(8),
.fixed-table td:nth-child(8) {
  width: 150px; 
}

.fixed-table th:nth-child(9),
.fixed-table td:nth-child(9) {
  width: 150px; 
}

.fixed-table th:nth-child(10),
.fixed-table td:nth-child(10) {
  width: 300px; 
}

.fixed-table th:nth-child(11),
.fixed-table td:nth-child(11) {
  width: 50px; 
}

th {
  background-color: #f2f2f2;
}


tr:hover {
  background-color: #ddd;
}

.profile-pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}

.profile-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.table-container {
  overflow-x: auto;
  max-width: 100%;
}

/* Add your other styles here */

