.new-tournament-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 30px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.new-tournament-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.new-tournament-form {
  display: flex;
  flex-direction: column;
  width: 400px;
}

.new-tournament-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.new-tournament-field label {
  font-weight: bold;
  color: #333;
}

.new-tournament-field input {
  margin-top: 1%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.new-tournament-field select {
  margin-top: 1%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.new-tournament-field input:focus {
  outline: none;
  border-color: #6c63ff;
}

.new-tournament-buttons {
  display: flex;
  justify-content: flex-start;
}

.new-tournament-save-btn,
.new-tournament-cancel-btn {
  padding: 10px 20px;
  margin-right: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.new-tournament-save-btn {
  background-color: #4caf50;
  color: white;
}

.new-tournament-cancel-btn {
  background-color: #f44336;
  color: white;
}

.new-tournament-save-btn:hover,
.new-tournament-cancel-btn:hover {
  opacity: 0.8;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.new-tournament-container {
  animation: slideIn 0.5s ease;
}

@keyframes btnBounce {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

.new-tournament-save-btn:hover,
.new-tournament-cancel-btn:hover {
  animation: btnBounce 0.3s ease;
}