.notification-container {
  width: 100%;
  height: 100%;
  /* max-width: 400px; */
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.select-container {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.select-container select {
  flex: 1;
  margin-right: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.select-container button {
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.search-container input[type="text"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.selected-people-container {
  margin-bottom: 10px;
}

.selected-people-container h3 {
  margin-bottom: 10px;
}

.selected-people-container ul {
  list-style: none;
  padding: 0;
}

.selected-people-container li {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.selected-people-container button {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #ff6347;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.input-container {
  margin-bottom: 10px;
}

.input-container label {
  display: block;
  margin-bottom: 5px;
}

.input-container input[type="text"],
.input-container textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}



.button-container {
  text-align: center;
}

.button-container button {
  padding: 10px 20px;
  margin-right: 10px;
  background-color:#000;
  color: #ff6347;
  border: none;
  border-radius: 10%;
  cursor: pointer;
}

.editsave{
  padding: 10px 20px;
  margin-top: 10px;
  background-color: #000;
  color: #ff6347;
  border: none;
  border-radius: 10%;
}

.button-container button:last-child {
  margin-right: 0;
  margin-top:50px;
}

