body {
  color: red;
}

.categories-container {
  align-items: flex-start;
  padding: 20px;
  flex-wrap: wrap;
}

.category-card {
  color: white;
  display: flex;
  background-color: #e55855;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  margin-bottom: 20px;
  font-family: Arial, sans-serif;
}

.title {
  margin-top: 0%;
  color: black;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
}

.close {
  position: absolute;
  top: -10px;
  right: 5px;
  font-size: 24px;
  color: white;
  cursor: pointer;
}

.custom-table {
  width: 100%; 
  border-collapse: collapse;
  margin-top: 20px;
}

.custom-table thead th {
  background-color: #e55855;
  color: white;
  padding: 10px;
  text-align: left;
}

.custom-table tbody td {
  padding: 5px; 
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.btn2 {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  color: #fff;
  margin-right: 10px;
  background-color: black;
}

.entered-data {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.entry-container {
  background-color: #f0f0f0;
  border: 1px solid #ccc; 
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.white-link {
  color: white;
  text-decoration: none;
}